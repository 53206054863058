import { pageData } from "@config/pages";
import { BaseLayout } from "@layouts/BaseLayout";
import { useOktaAuth } from "@okta/okta-react";
import Root from "@routes/root";
import { setLastVisited } from "@stores/lastVisited";
import { useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useFeatureAccess } from "@hooks/useFeatureAccess";
import { useApiTokenStore } from "@stores/store";
import { EmptyLayout } from "@layouts/EmptyLayout";
import useTracking from "@hooks/useTracking.tsx";
import { Button } from "flowbite-react";
import SubscriptionConfirmation from "@pages/subscription-confirmation";
import { useUserSubscription } from "@hooks/useUserSubscription";
import LoadingSpinner from "@components/loading/LoadingSpinner";

export const ProtectedRouter = () => {
  useTracking();
  const { isSubscribed, isFetched: isFetchedSubscription } =
    useUserSubscription();

  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const location = useLocation();
  const { hasAllAccess, isLoading } = useFeatureAccess();

  useEffect(() => {
    // if user got logged out, record the last visited page
    if (authState && !authState.isAuthenticated) {
      setLastVisited(
        location.pathname +
          "?" +
          new URLSearchParams(location.search).toString(),
      );
    }
    // removed location from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  useEffect(() => {
    if (
      location.pathname.includes("/home") &&
      isFetchedSubscription &&
      !isSubscribed
    ) {
      navigate("/pricing");
    }
  }, [isSubscribed, isFetchedSubscription, navigate]);

  const accessiblePages = Object.values(pageData).filter(
    ({ fAccessKeys, allowUnsubscribedAccess }) =>
      hasAllAccess(fAccessKeys || []) &&
      (isSubscribed || allowUnsubscribedAccess),
  );

  /**
   * show loading indicator to prevent showing 404 while feature access is loading
   */
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <div className="scale-75">
          <LoadingSpinner />
        </div>
      </div>
    );
  }

  return (
    <Routes>
      <Route element={<Root />}>
        <Route
          path="/subscription-confirmation"
          element={<SubscriptionConfirmation />}
        />
        <Route element={<BaseLayout />}>
          <Route path="/" element={<Navigate to={pageData.home.path} />} />
          {accessiblePages.map(({ path, component: Component }) => {
            return (
              <Route
                key={path}
                path={path}
                element={
                  useApiTokenStore.getState().apiToken ? (
                    <Component />
                  ) : (
                    <EmptyLayout />
                  )
                }
              />
            );
          })}
          <Route
            path="*"
            element={
              <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 dark:bg-gray-900 text-center px-4">
                <h1 className="text-6xl font-extrabold text-gray-900 dark:text-white mb-4">
                  404
                </h1>
                <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
                  Oops... Page not found.
                </p>
                <Button
                  size="lg"
                  className="text-white bg-black w-64 h-18 rounded-lg shadow-md"
                >
                  <a href="/home">BACK TO HOMEPAGE</a>
                </Button>
              </div>
            }
          />
        </Route>
      </Route>
    </Routes>
  );
};
