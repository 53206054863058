import { useQuery } from "@tanstack/react-query";
import { fetchUserSubscription } from "@services/subscriptions";
import config from "@config/config";

const CACHE_KEY = "user-subscription-cache";

function getCachedSubscription(): boolean {
  const cachedData = localStorage.getItem(CACHE_KEY);
  return cachedData ? JSON.parse(cachedData) : false;
}

function cacheSubscription(value: boolean) {
  localStorage.setItem(CACHE_KEY, JSON.stringify(value));
}

export function useUserSubscription() {
  const cachedSubscription = getCachedSubscription();

  const { data, isLoading, isError, isFetched, refetch } = useQuery<{
    is_subscribed: boolean;
  }>({
    queryKey: ["user-subscription"],
    queryFn: async () => {
      const response = await fetchUserSubscription();
      cacheSubscription(response?.is_subscribed);
      return response;
    },
    enabled: config.featureFlags.allowSignup,
  });

  if (!config.featureFlags.allowSignup) {
    return {
      isSubscribed: true,
      reFetchSubscription: () => {},
      isLoading: false,
      isError: false,
      isFetched: true,
    };
  }

  return {
    isSubscribed: cachedSubscription || data?.is_subscribed || false,
    reFetchSubscription: refetch,
    isLoading,
    isError,
    isFetched,
  };
}
