import { LIGHT_LOGO } from "@config/constants";
import { Button, Label, TextInput } from "flowbite-react";
import { Link } from "react-router-dom";
import { signup } from "@services/auth";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import config from "@config/config";
import { ApiError } from "@services/api";

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
};

type Field = {
  name: keyof FormData;
  label: string;
  type: string;
  required?: boolean;
  pattern?: RegExp;
};

const FIELDS: Field[] = [
  { name: "firstName", label: "First Name", type: "text", required: true },
  { name: "lastName", label: "Last Name", type: "text", required: true },
  {
    name: "email",
    label: "Email",
    type: "email",
    required: true,
    pattern: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
  },
  { name: "companyName", label: "Company Name", type: "text", required: true },
];

const Signup = () => {
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [formData, setFormData] = useState<FormData>(
    Object.fromEntries(FIELDS.map(({ name }) => [name, ""])) as FormData,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  const handleSignup = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!captchaToken) return;

    setIsLoading(true);
    setErrorMessage(null);

    try {
      await signup({ ...formData, captchaToken });
      setShowSuccess(true);
    } catch (error: ApiError | any) {
      setErrorMessage(
        JSON.stringify((error && error?.detail) || "Something went wrong"),
      );
    } finally {
      setIsLoading(false);
    }
  };

  if (showSuccess) {
    return (
      <div className="flex flex-col justify-center items-center h-screen gap-6">
        <img src={LIGHT_LOGO} alt="logo" className="w-40" />
        <div className="w-full max-w-sm p-6 bg-white rounded-[20px] shadow-md text-center">
          <h2 className="text-lg font-semibold text-black text-opacity-80">
            Signup Successful!
          </h2>
          <p className="text-gray-600 text-sm mt-2">
            Please check your email for verification.
          </p>
          <Link
            to="/login"
            className="mt-4 inline-block text-blue-500 hover:underline"
          >
            Go to Login
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen gap-8">
      <img src={LIGHT_LOGO} alt="logo" className="w-40" />
      <div className="w-full max-w-sm p-8 bg-white rounded-[20px] shadow-[0_0_50px_#00000038]">
        <h2 className="text-l font-semibold text-center text-black text-opacity-30 mb-6">
          Sign Up
        </h2>
        <form className="space-y-2" onSubmit={handleSignup}>
          {FIELDS.map(({ name, label, type, pattern }) => (
            <div key={name}>
              <Label htmlFor={name}>{label}</Label>
              <TextInput
                id={name}
                type={type}
                value={formData[name]}
                onChange={(e) =>
                  setFormData({ ...formData, [name]: e.target.value })
                }
                required
                pattern={pattern?.source}
                disabled={isLoading}
              />
            </div>
          ))}
          <ReCAPTCHA
            sitekey={config.goggleRecaptchaSiteKey}
            onChange={handleCaptchaChange}
          />
          <Button
            type="submit"
            className="w-full py-2 !mt-4"
            disabled={!captchaToken || isLoading}
            isProcessing={isLoading}
          >
            {isLoading ? "Signing Up..." : "Sign Up"}
          </Button>
          {errorMessage && (
            <p className="text-red-500 text-sm text-center mt-2">
              {errorMessage}
            </p>
          )}
        </form>
        <div className="mt-4 text-center">
          <Link to="/login" className="text-sm text-gray-500 hover:text-black">
            Have an account?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
