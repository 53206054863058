import { NavbarItem, NavbarRouteLink, Pages } from "@/types/navbar";
import { FiBook } from "react-icons/fi";
import { MdOutlineInsights, MdOutlineDataset } from "react-icons/md";
import { PiGraphBold } from "react-icons/pi";
import { RiDashboardLine } from "react-icons/ri";
// import { TbBook, TbWorld } from "react-icons/tb";
import { TbBook } from "react-icons/tb";
import config from "@config/config.ts";
// import { HiMiniChatBubbleOvalLeftEllipsis } from "react-icons/hi2";
import { FaUsers } from "react-icons/fa";
import { FeatureKeys } from "@config/features";
import { urlRoutes } from "./pages";

export const SiteNavbarItems: NavbarItem[] = [
  {
    key: Pages.insights,
    type: "dropdown",
    title: "Explore",
    items: [
      {
        key: Pages.insights,
        type: "link",
        title: "Build",
        route: "/insights",
        description: "Explore Entities, Insights, and Events",
        icon: MdOutlineInsights,
      },
      {
        key: Pages.data,
        type: "link",
        title: "Library",
        route: "/data-library",
        description: "View the Platform's data assets",
        icon: MdOutlineDataset,
        fAccessKey: FeatureKeys.DataSources,
      },
      // {
      //   key: Pages.dashboards,
      //   type: "link",
      //   title: "Dashboards",
      //   description: "Unlock industry focused Dashboards",
      //   route: "/dashboards/",
      //   icon: RiDashboardLine,
      // },
      {
        key: Pages.hub,
        type: "link",
        title: "Hub",
        description: "Browse our subscription data products",
        route: urlRoutes.hub,
        icon: RiDashboardLine,
      },
      {
        key: Pages.ontology,
        type: "link",
        title: "Ontology",
        route: "/ontology",
        description: "Structured entity relationships and hierarchies",
        icon: PiGraphBold,
      },
    ],
  },

  {
    key: Pages.workspace,
    type: "link",
    title: "Workspace",
    route: "/workspaces",
    icon: FiBook,
  },
  ...(config.showAppsNavigation
    ? ([
        {
          key: Pages.apps,
          type: "dropdown",
          title: "Apps",
          items: [
            {
              key: Pages.cohort,
              type: "link",
              title: "Cohort Builder",
              route: "/cohort-builder",
              description: "Create and manage Cohorts",
              icon: FaUsers,
              fAccessKey: FeatureKeys.CohortBuilder,
            },
            // {
            //   key: Pages.openSourceDataFeeds,
            //   type: "link",
            //   title: "Open Source Data Feeds",
            //   route: "/open-source-data-feeds",
            //   description: "Explore open source data feeds",
            //   icon: TbWorld,
            // },
            // {
            //   key: Pages.chat,
            //   type: "link",
            //   title: "Chat",
            //   route: "/chat",
            //   description: "Chat with our AI assistant",
            //   icon: HiMiniChatBubbleOvalLeftEllipsis,
            //   fAccessKey: FeatureKeys.Eclair,
            // },
          ],
        },
      ] as NavbarItem[])
    : ([] as NavbarItem[])),

  {
    key: Pages.api,
    type: "link",
    title: "Developers",
    route: "/api",
    description: "Explore live APIs",
    icon: TbBook,
    fAccessKey: FeatureKeys.DeveloperAPIs,
  },
  {
    key: Pages.docs,
    type: "link",
    title: "Docs",
    route: "/docs/",
    description: "Learn about Carbon Arc platform",
    isExternalSite: true,
    link: config.platformDocsUrl,
    canAccess: true,
    icon: FiBook,
    fAccessKey: FeatureKeys.Docs,
  },
];

export const SiteNavbarRouteLinks: NavbarRouteLink[] = SiteNavbarItems.reduce(
  (acc, item) => {
    if (item.type === "link" && !item.isExternalSite) {
      acc.push(item as NavbarRouteLink);
    }

    if (item.type === "dropdown") {
      const filteredItems = item.items.filter(
        (subItem) => subItem.type === "link" && !subItem.isExternalSite,
      );
      acc.push(...(filteredItems as NavbarRouteLink[]));
    }

    return acc;
  },
  [] as NavbarRouteLink[], // Initialize the accumulator as an empty array of NavbarRouteLink
);

export const getNavbarItem = (page: Pages): NavbarItem | undefined => {
  const findNavbarItem = (items: NavbarItem[]): NavbarItem | undefined => {
    for (const item of items) {
      if (item.key === page) return item;
      if (item.type === "dropdown" && item.items?.length) {
        const found = findNavbarItem(item.items);
        if (found) return found;
      }
    }
    return undefined;
  };

  return findNavbarItem(SiteNavbarItems);
};
