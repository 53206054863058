import { EmptyLayout } from "@layouts/EmptyLayout";
import { fetchUserProfile, setAuthTokenCookieApi } from "@services/auth";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useApiTokenStore } from "@stores/store";

export function ApiAuth({ children }: { children: React.ReactNode }) {
  const { doesApiTokenExist, setApiToken, setUserInfo } = useApiTokenStore();
  const doesApiTokenAlreadyExist = doesApiTokenExist();
  const { data, isLoading, isError, isFetched } = useQuery({
    queryKey: ["user-profile"],
    queryFn: () => fetchUserProfile(),
  });
  useQuery({
    queryKey: ["set-auth-token-cookie"],
    queryFn: () => setAuthTokenCookieApi(),
  });

  useEffect(() => {
    if (isFetched && data) {
      setApiToken({
        clientId: data.client.short_name,
        api_token: data.api_tokens[0].token,
      });
      setUserInfo({
        clientUUID: data.client.id,
        clientId: data.client.client_id,
        userEmail: data.email,
        userUUID: data.api_tokens[0].user_id,
      });
    }
  }, [isFetched, data, setApiToken, setUserInfo]);

  // TODO: Add a loading spinner here
  if (isLoading && !doesApiTokenAlreadyExist) return <EmptyLayout />;
  if (isError) {
    toast.error(
      `It looks like you don't have permission to view this app. Please contact us for further assistance.`,
    );
    return <EmptyLayout />;
  }

  return <>{children}</>;
}
