import { Location, useLocation } from "react-router-dom";
import { logUserActivity } from "@services/user-tracking";
import {
  clickTrackingRules,
  pageTrackingRules,
  TrackingEvent,
} from "@config/trackingRules.ts";
import { useDebounce } from "react-use";
import { useCallback, useEffect, useRef } from "react";

const useTracking = () => {
  const location = useLocation();
  const trackedEventsRef = useRef(new Set<string>());

  const trackEvent = async (eventData: TrackingEvent) => {
    const trackID = `${eventData.activity}-${eventData.item_type}-${eventData.item_id}`;

    if (!trackedEventsRef.current.has(trackID)) {
      trackedEventsRef.current.add(trackID);
      await logUserActivity(eventData);
    }
  };

  const trackPageEvents = useCallback((location: Location) => {
    for (const rule of pageTrackingRules) {
      if (rule.matchesLocation(location)) {
        const eventData = rule.generateEventData(location);
        trackEvent(eventData);
      }
    }
  }, []);

  const trackClickEvents = useCallback(
    (event: MouseEvent, location: Location) => {
      const target = event.target as HTMLElement;
      const trackingActivity = target
        .closest("[data-tracking-activity]")
        ?.getAttribute("data-tracking-activity");

      if (trackingActivity) {
        for (const rule of clickTrackingRules) {
          if (rule.matches(trackingActivity, location)) {
            const eventData = rule.generateEventData(
              trackingActivity,
              location,
            );
            trackEvent(eventData);
          }
        }
      }
    },
    [],
  );

  useDebounce(
    () => {
      trackPageEvents(location);
    },
    500,
    [location, trackPageEvents],
  );

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      setTimeout(() => trackClickEvents(event, location), 0); // Non-blocking tracking
    };

    document.addEventListener("click", handleClick, { passive: true });
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [location, trackClickEvents]);
};

export default useTracking;
