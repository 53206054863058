import { AuthRoute } from "@components/Okta";
import { LoginCallback } from "@okta/okta-react";
import Login from "@pages/login";
import Signup from "@pages/signup";
import { ProtectedRouter } from "@routes/protected";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import config from "@config/config";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthRoute />}>
      {config.featureFlags.allowSignup && (
        <Route path="/signup" element={<Signup />} />
      )}
      <Route path="/login" element={<Login />} />
      <Route path={`/login/callback`} element={<LoginCallback />} />
      <Route path="/*" element={<ProtectedRouter />} />
    </Route>,
  ),
);
