import config from "@config/config";
import { apiFetch } from "@/services/api";

const SUBSCRIPTIONS_BASE_URL = `${config.carcAuth.url}/subscriptions`;

export type TPaymentSetup = {
  plan_data: Record<string, any>;
  success_url: string;
  cancel_url: string;
};

export const subscribe = async (data: TPaymentSetup): Promise<any> => {
  return apiFetch(`${SUBSCRIPTIONS_BASE_URL}/subscribe`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export const getClientBillingPortalUrl = async (data: {
  return_url: string;
}) => {
  return apiFetch(`${SUBSCRIPTIONS_BASE_URL}/self/billing-portal`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};

export async function fetchUserSubscription() {
  return apiFetch(`${SUBSCRIPTIONS_BASE_URL}/self/status`);
}

export async function fetchUserBalance() {
  return apiFetch(`${SUBSCRIPTIONS_BASE_URL}/self/balance`);
}
