import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";

interface DropdownMenuProps {
  items: Array<{
    name: string;
    href?: string;
    onClick?: () => void;
    icon: React.ElementType;
  }>;
  onClose: () => void;
  darkThemeToggle?: React.ReactNode;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  items,
  onClose,
  darkThemeToggle,
}) => {
  const itemList = useMemo(() => {
    return items.reduce<React.ReactNode[]>(
      (acc, { name, href, onClick, icon: Icon }, index) => {
        const isFirstItem = index === 0;
        const isLastItem = index === items.length - 1;

        acc.push(
          <React.Fragment key={name}>
            {href ? (
              <NavLink
                to={href}
                onClick={onClose}
                className={`flex items-center p-3 transition duration-150 ease-in-out hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none ${
                  isFirstItem
                    ? "rounded-t-md"
                    : isLastItem
                      ? "rounded-b-md"
                      : "rounded-none"
                } ${
                  !isLastItem
                    ? "border-b border-gray-200 dark:border-gray-700"
                    : ""
                }`}
              >
                <Icon className="size-6 text-gray-500 dark:text-gray-300 mr-3" />
                <span className="flex-1 font-medium text-gray-900 dark:text-white">
                  {name}
                </span>
              </NavLink>
            ) : (
              <button
                onClick={() => {
                  if (onClick) onClick();
                  onClose();
                }}
                className={`flex items-center w-full p-3 transition duration-150 ease-in-out hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none ${
                  isFirstItem
                    ? "rounded-t-md"
                    : isLastItem
                      ? "rounded-b-md"
                      : "rounded-none"
                } ${
                  !isLastItem
                    ? "border-b border-gray-200 dark:border-gray-700"
                    : ""
                }`}
              >
                <Icon className="h-6 w-6 text-gray-500 dark:text-gray-300 mr-3" />
                <span className="font-medium text-gray-900 dark:text-white">
                  {name}
                </span>
              </button>
            )}
          </React.Fragment>,
        );

        if (name === "My Team" && darkThemeToggle) {
          acc.push(
            <>
              <div
                key="separator-after-my-team"
                className="border-b border-gray-200 dark:border-gray-700"
              />
              <div key="dark-theme-toggle">{darkThemeToggle}</div>
              <div
                key="separator-after-dark-theme-toggle"
                className="border-b border-gray-200 dark:border-gray-700"
              />
            </>,
          );
        }

        return acc;
      },
      [],
    );
  }, [items, onClose, darkThemeToggle]);

  return <ul>{itemList}</ul>;
};

export default DropdownMenu;
