import { Location } from "react-router-dom";

export type TrackingEvent = {
  activity: string;
  item_type: string;
  item_id: string;
  item_metadata: Record<string, any>;
};

type TrackingRuleConfig = {
  pathPrefix: string;
  requiredParams: string[];
  activity: string;
  item_type: string;
  idFields: Record<string, string>;
};

const pageTrackingRuleConfigs: TrackingRuleConfig[] = [
  {
    // Track Entity View
    pathPrefix: "/insights/details-next",
    requiredParams: ["entityId", "representation"],
    activity: "VIEW",
    item_type: "entity",
    idFields: {
      entity_id: "entityId",
      entity_representation_name: "representation",
    },
  },
  {
    // Track Insight View
    pathPrefix: "/insights/details-next",
    requiredParams: ["insight"],
    activity: "VIEW",
    item_type: "insight",
    idFields: { name: "insight" },
  },
  {
    // Track Topic View
    pathPrefix: "/insights/details-next",
    requiredParams: ["topic"],
    activity: "VIEW",
    item_type: "topic",
    idFields: { name: "topic" },
  },
  {
    // Track Subject View
    pathPrefix: "/insights/subjects",
    requiredParams: ["subject"],
    activity: "VIEW",
    item_type: "subject",
    idFields: { name: "subject" },
  },
  {
    // Track Event Subject  View
    pathPrefix: "/insights/events",
    requiredParams: ["subject"],
    activity: "VIEW",
    item_type: "subject",
    idFields: { name: "subject" },
  },
  {
    // Track Dashboard View
    pathPrefix: "/dashboards",
    requiredParams: ["dashboardID"],
    activity: "VIEW",
    item_type: "dashboard",
    idFields: { dashboard_uuid: "dashboardID" },
  },
  {
    // Track Search Entity
    pathPrefix: "/insights/details-next",
    requiredParams: ["entityId", "representation", "entitiesSearchTerm"],
    activity: "SEARCH",
    item_type: "entity",
    idFields: {
      entity_id: "entityId",
      entity_representation_name: "representation",
    },
  },
  {
    // Track Search Subject
    pathPrefix: "/insights/subjects",
    requiredParams: ["subject", "subjectsSearchTerm"],
    activity: "SEARCH",
    item_type: "subject",
    idFields: { name: "subject" },
  },
  {
    // Track Search Insight
    pathPrefix: "/insights/details-next",
    requiredParams: ["insight", "insightsSearchTerm"],
    activity: "SEARCH",
    item_type: "insight",
    idFields: { name: "insight", search_text: "insightsSearchTerm" },
  },
];

const clickTrackingRuleConfigs: TrackingRuleConfig[] = [
  {
    // Track Entity Build
    pathPrefix: "/insights/details-next",
    requiredParams: ["entityId", "representation"],
    activity: "BUILD",
    item_type: "entity",
    idFields: {
      entity_id: "entityId",
      entity_representation_name: "representation",
    },
  },
  {
    // Track Insight Build
    pathPrefix: "/insights/details-next",
    requiredParams: ["insight"],
    activity: "BUILD",
    item_type: "insight",
    idFields: { name: "insight" },
  },
];

const generateTrackingFunctions = (config: TrackingRuleConfig) => ({
  matchesLocation: (location: Location) => {
    if (!location.pathname.startsWith(config.pathPrefix)) return false;
    const params = new URLSearchParams(location.search);
    return config.requiredParams.every((param) => !!params.get(param));
  },
  generateEventData: (location: Location) => {
    const params = new URLSearchParams(location.search);
    const item_id = JSON.stringify(
      Object.fromEntries(
        Object.entries(config.idFields)
          .map(([itemIdField, queryParam]) => [
            itemIdField,
            params.get(queryParam),
          ])
          .filter(([_, value]) => value), // Remove undefined values
      ),
    );

    return {
      activity: config.activity,
      item_type: config.item_type,
      item_id,
      item_metadata: {
        path: location.pathname,
        search_query: location.search,
      },
    };
  },
});

type PageTrackingRule = {
  matchesLocation: (location: Location) => boolean;
  generateEventData: (location: Location) => TrackingEvent;
};

type ClickTrackingRule = {
  matches: (activityName: string, location: Location) => boolean;
  generateEventData: (
    activityName: string,
    location: Location,
  ) => TrackingEvent;
};

export const pageTrackingRules: PageTrackingRule[] =
  pageTrackingRuleConfigs.map(generateTrackingFunctions);

export const clickTrackingRules: ClickTrackingRule[] =
  clickTrackingRuleConfigs.map((config) => ({
    matches: (activityName, location) =>
      activityName === config.activity &&
      generateTrackingFunctions(config).matchesLocation(location),
    generateEventData: (_, location) =>
      generateTrackingFunctions(config).generateEventData(location),
  }));
