const trimRight = (str: string, char: string) =>
  str.replace(new RegExp(`[${char}]*$`, "g"), "");

const trimLeft = (str: string, char: string) =>
  str.replace(new RegExp(`^[${char}]*`, "g"), "");

const trim = (str: string, char: string) =>
  trimLeft(trimRight(str, char), char);

export const removeRightSlashes = (str: string) => str.replace(/\/*$/g, "");

export const removeLeftSlashes = (str: string) => str.replace(/^\/*/g, "");

/**
 * Converts a string into a file-friendly format.
 *
 * Replaces non-alphanumeric characters with underscores, consolidates
 * multiple underscores, and trims leading/trailing underscores. If the
 * input is undefined or empty, returns an empty string.
 *
 * @param {string} [name] - The string to format.
 * @returns {string} - The formatted string or an empty string.
 */
export const filefyString = (name?: string) =>
  name
    ? trim(
        name
          .toString()
          .replace(/[^a-z0-9]/gi, "_")
          .replaceAll(/_+/g, "_")
          .toLowerCase()
          .trim(),
        "_",
      ).trim()
    : "";

/**
 * Formats an array of names into a file-friendly string.
 *
 * Maps names using `filefyString`, joins them with "__", and appends "__more"
 * if the count exceeds the specified maximum (default is 3). Returns an empty
 * string for invalid input.
 *
 * @param {string[]} [names] - Names to format.
 * @param {number} [max=3] - Max names to include.
 * @returns {string} - Formatted string or empty.
 */
export const filefyArray = (names?: string[], max = 3) =>
  names && names.length > 0
    ? names?.map(filefyString).slice(0, max).join("__") +
      (names.length > max ? "__more" : "")
    : "";

export const copyToClipboard = (textToCopy: string) => {
  if (typeof navigator?.clipboard?.writeText === "function") {
    navigator.clipboard.writeText(textToCopy);
  } else {
    const element = document.body;
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    element.appendChild(textArea);
    textArea.select();
    textArea.setSelectionRange(0, 99999);
    document.execCommand("copy");
    element.removeChild(textArea);
  }
};

export const caseInsensitiveSearch = (source: string, query: string) => {
  return source?.toLowerCase().includes(query?.toLowerCase());
};

export const isStringSimilar = (str1Raw?: string, str2Raw?: string) => {
  if (typeof str1Raw !== "string" || typeof str2Raw !== "string") {
    return 0;
  }

  const str1 = str1Raw.trim().toLowerCase();
  const str2 = str2Raw.trim().toLowerCase();

  const minLength = Math.min(str1.length, str2.length);
  let matchingChars = 0;
  for (let i = 0; i < minLength; i++) {
    if (str1[i] === str2[i]) {
      matchingChars++;
    }
  }

  const percentage = (matchingChars / minLength) * 100;
  return percentage;
};
