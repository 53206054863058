import { apiFetch } from "@services/api.ts";

const USER_TRACKING_BASE_URL = "/user-tracking";

export const logUserActivity = async (data: {
  activity: string;
  item_type: string;
  item_id: string;
  item_metadata: Record<string, any>;
}) => {
  return apiFetch(`/v2${USER_TRACKING_BASE_URL}/log-activity`, {
    method: "POST",
    body: JSON.stringify(data),
  });
};
