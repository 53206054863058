import { useEffect, useState } from "react";
import { useUserSubscription } from "@hooks/useUserSubscription";
import { useNavigate } from "react-router-dom";
import { Card, Spinner } from "flowbite-react";
import { FaCheckCircle } from "react-icons/fa";
import { GoXCircleFill } from "react-icons/go";

const MAX_ATTEMPTS = 45;
const POLLING_INTERVAL = 1000;

export default function SubscriptionConfirmation() {
  const navigate = useNavigate();
  const { isSubscribed, reFetchSubscription } = useUserSubscription();
  const [attempts, setAttempts] = useState(0);
  const [status, setStatus] = useState<"loading" | "success" | "failed">(
    "loading",
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (!isSubscribed && attempts < MAX_ATTEMPTS) {
      interval = setInterval(async () => {
        setAttempts((prev) => prev + 1);
        await reFetchSubscription();
      }, POLLING_INTERVAL);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isSubscribed, attempts, reFetchSubscription]);

  useEffect(() => {
    if (isSubscribed) {
      setStatus("success");
      setTimeout(() => navigate("/home"), 1500);
    } else if (attempts >= MAX_ATTEMPTS) {
      setStatus("failed");
      setTimeout(() => navigate("/pricing"), 1500);
    }
  }, [isSubscribed, attempts, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Card className="w-full max-w-sm p-6 shadow-lg text-center">
        {status === "loading" && <Spinner size="lg" />}
        {status === "success" && (
          <FaCheckCircle className="w-12 h-12 text-green-500 mx-auto" />
        )}
        {status === "failed" && (
          <GoXCircleFill className="w-12 h-12 text-red-500 mx-auto" />
        )}

        <p className="mt-4 text-lg font-medium">
          {status === "success"
            ? "Your subscription is now active!"
            : status === "failed"
              ? "We couldn’t verify your payment"
              : "Verifying your subscription..."}
        </p>
      </Card>
    </div>
  );
}
