import config from "@config/config";
import { apiFetch } from "@services/api";

const AUTH_BASE_URL = config.carcAuth.url;

export async function fetchUserProfile() {
  const re = await apiFetch(`${AUTH_BASE_URL}/users/me/profile`);
  return re;
}
export async function fetchUserFeatureAccess() {
  return apiFetch(
    `${AUTH_BASE_URL}/users/me/products/${config.carcAuth.product_id}/feature-access`,
  );
}

export type TMyRole = {
  role: string;
  product_id: string;
  user_id: string;
};

export async function fetchUserRole() {
  return apiFetch<TMyRole>(
    `${AUTH_BASE_URL}/users/me/product/${config.carcAuth.product_id}/role`,
  );
}

export async function setAuthTokenCookieApi() {
  return apiFetch(`${AUTH_BASE_URL}/auth/cookie/set`, {
    method: "POST",
    credentials: "include",
  });
}

export async function clearAuthTokenCookieApi() {
  return apiFetch(`${AUTH_BASE_URL}/auth/cookie/clear`, {
    method: "POST",
    credentials: "include",
  });
}

export type TSignupParams = {
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
  captchaToken: string;
};

export async function signup({
  email,
  firstName,
  lastName,
  companyName,
  captchaToken,
}: TSignupParams) {
  return apiFetch(`${AUTH_BASE_URL}/auth/signup`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      email,
      first_name: firstName,
      last_name: lastName,
      client: {
        business_name: companyName,
      },
    }),
    headers: {
      "X-Captcha-Token": captchaToken,
    },
  });
}
