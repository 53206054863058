import React from "react";
import { FiBell, FiUser, FiUsers } from "react-icons/fi";
import { IoDocumentOutline } from "react-icons/io5";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { FeatureKeys } from "@config/features";

export enum Flags {
  SHOW_HOME = "show_home",
}

export type PageData = {
  label: string;
  path: string;
  badge?: string;
  icon?: React.ElementType;
  component: React.LazyExoticComponent<React.ComponentType<any>>;
  fAccessKeys?: FeatureKeys[];
  allowUnsubscribedAccess?: boolean;
};

export const Divider = "";

export enum Page {
  HOME = "home",
  MY = "my/",
  MY_PROFILE = "my/profile",
  MY_NOTIFICATIONS = "my/notifications",
  MY_TEAM = "team",
  MY_BILLING = "billing",
  DOCUMENT_MANAGEMENT = "my/document-management",
  DOCUMENT_MANAGEMENT_DONE = "my/document-management/done",
  PROFILE = "profile",
  RESET_PASSWORD = "reset_password",
  INSIGHTS = "insights",
  INSIGHTS_BUILDER = "insights/builder",
  INSIGHTS_DETAILS = "insights/details",
  INSIGHTS_DETAILS_NEW = "insights/details-next", // temporary to not override current insights details page
  ONTOLOGY = "ontology",
  ONTOLOGY_DETAILS = "ontology/details",
  ONTOLOGY_CATEGORY_DETAILS = "ontology/category-details",
  WORKSPACES = "workspaces",
  WORKSPACES_COHORTS = "workspaces/cohorts",
  WORKSPACES_COHORTS_CREATE = "workspaces/cohorts/new",
  WORKSPACES_ANALYZE = "workspaces/analyze",
  DASHBOARDS = "dashboards",
  DATA_LIBRARY = "data-library",
  DATA_LIBRARY_ALL_DATA = "data-library/all-data",
  API = "api",
  ALL_DATA = "all-data",
  SEARCH = "search",
  ENTITY_WORKBOOKS = "entity_workbooks",
  ENTITY_WORKBOOK_DETAILS = "entity_workbook",
  REQUEST_ENTITY = "request_entity",
  CONTACT_US = "contact_us",
  COHORT_BUILDER = "cohort-builder",
  COHORT_BUILDER_NEW = "cohort-builder/new",
  COHORT_BUILDER_PANEL = "cohort-builder/new/",
  COHORT_BUILDER_REPORT = "cohort-builder/report",
  CHAT = "chat",
  WEB_CONTENT_DATA_FEEDS = "web-content-data-feeds",
  HUB = "hub",
  PRICING = "pricing",
}
export const pageData: Record<Page, PageData> = {
  [Page.HOME]: {
    label: "Home",
    path: `/${Page.HOME}`,
    component: React.lazy(() => import("@pages/home")),
    allowUnsubscribedAccess: true,
  },
  [Page.PROFILE]: {
    label: "Profile",
    path: `/${Page.PROFILE}`,
    component: React.lazy(() => import("@pages/profile")),
    allowUnsubscribedAccess: true,
  },
  [Page.RESET_PASSWORD]: {
    label: "Reset Password",
    path: `/${Page.RESET_PASSWORD}`,
    component: React.lazy(() => import("@pages/reset-password")),
    allowUnsubscribedAccess: true,
  },
  [Page.INSIGHTS]: {
    label: "Insights",
    path: `/${Page.INSIGHTS}/:group?`,
    component: React.lazy(() => import("@pages/insights/index")),
  },
  [Page.INSIGHTS_BUILDER]: {
    label: "Insights Builder",
    path: `/${Page.INSIGHTS_BUILDER}`,
    component: React.lazy(() => import("@pages/insights/builder")),
  },
  [Page.INSIGHTS_DETAILS]: {
    label: "Insights Details",
    path: `/${Page.INSIGHTS_DETAILS}`,
    component: React.lazy(() => import("@pages/insights/details")),
  },
  [Page.INSIGHTS_DETAILS_NEW]: {
    label: "Insights Details",
    path: `/${Page.INSIGHTS_DETAILS_NEW}`,
    component: React.lazy(() => import("@pages/insights/DetailsNew")),
  },
  [Page.ONTOLOGY]: {
    label: "Ontology",
    path: `/${Page.ONTOLOGY}/:type?`,
    component: React.lazy(() => import("@pages/ontology")),
  },
  [Page.ONTOLOGY_DETAILS]: {
    label: "Ontology",
    path: `/${Page.ONTOLOGY_DETAILS}`,
    component: React.lazy(
      () => import("@pages/ontology/OntologyEntityDetails"),
    ),
  },
  [Page.ONTOLOGY_CATEGORY_DETAILS]: {
    label: "Ontology",
    path: `/${Page.ONTOLOGY_CATEGORY_DETAILS}`,
    component: React.lazy(
      () => import("@pages/ontology/OntologyCategoryHierarchyDetails"),
    ),
  },
  [Page.WORKSPACES]: {
    label: "Workspace",
    path: "/workspaces",
    component: React.lazy(() => import("@pages/workspaces/workbooks")),
  },
  [Page.WORKSPACES_COHORTS]: {
    label: "Cohorts",
    path: `/${Page.WORKSPACES_COHORTS}`,
    component: React.lazy(() => import("@pages/workspaces/cohorts")),
    fAccessKeys: [FeatureKeys.CohortBuilder],
  },
  [Page.WORKSPACES_COHORTS_CREATE]: {
    label: "New Cohorts",
    path: `/${Page.WORKSPACES_COHORTS_CREATE}/:category?`,
    component: React.lazy(() => import("@pages/workspaces/cohorts-create")),
    fAccessKeys: [FeatureKeys.CohortBuilder],
  },
  [Page.WORKSPACES_ANALYZE]: {
    label: "Workspace analyze",
    path: `/${Page.WORKSPACES_ANALYZE}`,
    component: React.lazy(() => import("@pages/workspaces-analyze")),
  },
  [Page.DASHBOARDS]: {
    label: "Dashboards",
    path: "/dashboards/*",
    component: React.lazy(() => import("@pages/dashboards")),
  },
  [Page.DATA_LIBRARY]: {
    label: "Library",
    path: "/data-library/:tab?",
    component: React.lazy(() => import("@pages/data-library")),
  },
  [Page.DATA_LIBRARY_ALL_DATA]: {
    label: "All Data in Data Library",
    path: "/data-library/all-data/:type/:key",
    component: React.lazy(() => import("@pages/data-library-all-data")),
  },
  [Page.API]: {
    label: "API",
    path: "/api",
    component: React.lazy(() => import("@pages/api-explore")),
  },
  [Page.ALL_DATA]: {
    label: "All Data",
    path: "/all-data/:type/:key",
    component: React.lazy(() => import("@pages/all-data")),
  },
  [Page.SEARCH]: {
    label: "Search Page",
    path: "/search",
    component: React.lazy(() => import("@pages/search")),
  },
  [Page.ENTITY_WORKBOOKS]: {
    label: "Entity Workbooks",
    path: "/entity-workbooks",
    component: React.lazy(() => import("@pages/entity-workbooks")),
  },
  [Page.ENTITY_WORKBOOK_DETAILS]: {
    label: "Entity Workbook Details",
    path: "/entity-workbook/:entityWorkbookID",
    component: React.lazy(() => import("@pages/entity-workbook-details")),
  },
  [Page.REQUEST_ENTITY]: {
    label: "Request An Entity",
    path: "/request-entity",
    component: React.lazy(() => import("@pages/request-entity")),
  },
  [Page.CONTACT_US]: {
    label: "Contact Us",
    path: "/contact-us",
    component: React.lazy(() => import("@pages/contact-us")),
    allowUnsubscribedAccess: true,
  },
  [Page.MY]: {
    label: "My",
    path: Page.MY,
    component: React.lazy(() => import("@pages/My/index")),
  },
  [Page.MY_PROFILE]: {
    label: "Profile",
    path: Page.MY_PROFILE,
    icon: FiUser,
    component: React.lazy(() => import("@pages/My/MyAccount")),
  },
  [Page.MY_NOTIFICATIONS]: {
    label: "Notifications",
    path: Page.MY_NOTIFICATIONS,
    icon: FiBell,
    component: React.lazy(() => import("@pages/My/MyNotifications")),
  },
  [Page.MY_TEAM]: {
    label: "Team",
    path: Page.MY_TEAM,
    icon: FiUsers,
    component: React.lazy(() => import("@pages/My/MyTeam")),
  },
  [Page.MY_BILLING]: {
    label: "Billing",
    path: Page.MY_BILLING,
    icon: LiaFileInvoiceDollarSolid,
    component: React.lazy(() => import("@pages/My/MyBilling")),
  },
  [Page.DOCUMENT_MANAGEMENT]: {
    label: "Documents",
    path: Page.DOCUMENT_MANAGEMENT,
    icon: IoDocumentOutline,
    component: React.lazy(() => import("@pages/My/DocumentManagement")),
  },
  [Page.DOCUMENT_MANAGEMENT_DONE]: {
    label: "Documents",
    path: Page.DOCUMENT_MANAGEMENT_DONE,
    icon: IoDocumentOutline,
    component: React.lazy(() => import("@pages/My/DocumentManagementDone")),
  },
  [Page.CHAT]: {
    label: "Eclair",
    path: `/${Page.CHAT}/:threadId?/*`,
    component: React.lazy(() => import("@pages/Eclair")),
  },
  [Page.COHORT_BUILDER]: {
    label: "Cohort Builder",
    path: `/${Page.COHORT_BUILDER}/:type?`,
    component: React.lazy(() => import("@pages/cohort-builder/index")),
    fAccessKeys: [FeatureKeys.CohortBuilder],
  },
  [Page.COHORT_BUILDER_REPORT]: {
    label: "Cohort Builder Report",
    path: `/${Page.COHORT_BUILDER_REPORT}/:id?`,
    component: React.lazy(() => import("@pages/cohort-builder/report/index")),
    fAccessKeys: [FeatureKeys.CohortBuilder],
  },
  [Page.COHORT_BUILDER_NEW]: {
    label: "Build New Cohort",
    path: `/${Page.COHORT_BUILDER_NEW}`,
    component: React.lazy(() => import("@pages/cohort-builder/panels/index")),
    fAccessKeys: [FeatureKeys.CohortBuilder],
  },
  [Page.COHORT_BUILDER_PANEL]: {
    label: "Build New Cohort",
    path: `/${Page.COHORT_BUILDER_NEW}/:type?`,
    component: React.lazy(
      () => import("@pages/cohort-builder/panels/base-panel"),
    ),
    fAccessKeys: [FeatureKeys.CohortBuilder],
  },
  [Page.WEB_CONTENT_DATA_FEEDS]: {
    label: "Open Source Data Feeds",
    path: "/open-source-data-feeds",
    component: React.lazy(() => import("@pages/web-content-data-feeds")),
  },
  [Page.HUB]: {
    label: "Hub",
    path: `/${Page.HUB}`,
    component: React.lazy(() => import("@pages/hub")),
  },
  [Page.PRICING]: {
    label: "Plans & Pricing",
    path: `/${Page.PRICING}`,
    component: React.lazy(() => import("@pages/pricing")),
    allowUnsubscribedAccess: true,
  },
};

/**
 * Create URL routes from pageData for page navigation.
 */
type PageKeys = keyof typeof pageData;
export const urlRoutes = Object.keys(pageData).reduce(
  (acc, key) => {
    acc[key as PageKeys] = pageData[key as Page].path;
    return acc;
  },
  {} as Record<PageKeys, string>,
);
