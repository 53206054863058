import Smartlook from "smartlook-client";

const SMARTLOOK_KEY = "71ee2884cb1e0d00f12424be44e6ededbfa3f4cb";

/**
 * Initializes Smartlook if it hasn't been initialized yet.
 */
export const initSmartlook = () => {
  if (typeof window !== "undefined" && !(window as any).smartlook) {
    Smartlook.init(SMARTLOOK_KEY);
  }
};

/**
 * Tracks an event in Smartlook.
 * @param eventName - The name of the event.
 * @param properties - (Optional) Additional event properties.
 */
export const trackEvent = (
  eventName: string,
  properties?: Record<string, any>,
) => {
  if (typeof window !== "undefined" && (window as any).smartlook) {
    Smartlook.track(eventName, properties || {});
  }
};

/**
 * Identifies a user in Smartlook.
 * @param userId - The unique identifier of the user.
 * @param userProps - (Optional) Additional user properties (e.g., email, name).
 */
export const identifyUser = (
  userId: string,
  userProps?: Record<string, any>,
) => {
  if (typeof window !== "undefined" && (window as any).smartlook) {
    Smartlook.identify(userId, userProps || {});
  }
};
